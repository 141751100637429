// Include our external dependencies.
import * as Sentry from "@sentry/browser";

Sentry.init({
  dsn: window.indigen.SENTRY_DSN,
  environment: `Indigen : v${window.indigen.version}`,
  release: `Indigen@${window.indigen.version}`,
  ignoreErrors: [
    "SecurityError: The operation is insecure.",
    'GraphQL error: Unauthorized: {"errors":{"detail":"Authentication credentials were not provided."}}',
    'GraphQL error: Unauthorized: {"errors":{"detail":"Signature has expired."}}',
    "Invariant Violation: 16 (see https://github.com/apollographql/invariant-packages)",
    "QuotaExceededError: The quota has been exceeded.",
  ],
  beforeSend(event) {
    if (event.exception) {
      Sentry.showReportDialog({
        eventId: event.event_id,
        user: { email: window.indigen.userEmail },
      });
    }
    return window.indigen.environment === "production" ? event : false;
  },
});
